<template>
  <div class="content">
    <div>
      <a-row :gutter="16">
        <a-col :span="7">
          <a-input v-model="form.studyId" placeholder="Study Name" size="large" />
        </a-col>
        <a-col :span="7">
          <a-input v-model="form.type" placeholder="Type" size="large" />
        </a-col>
        <a-col :span="7">
          <a-input v-model="form.serialnumber" placeholder="Serial number" size="large" />
        </a-col>
        <a-col :span="3">
          <a-button block class="btn">Clear filter</a-button>
        </a-col>
      </a-row>
    </div>
    <div style="margin-top: 16px">
      <a-table :dataSource="dataSource" :columns="columns" :locale="{ emptyText: ' No results were found.' }">
      </a-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      dataSource: [],
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center',
        },
        {
          title: 'study id',
          dataIndex: 'studyId',
          ellipsis: true,
          align: 'center',
        },
        {
          title: 'sensorsId',
          dataIndex: 'sensorsId',
          ellipsis: true,
          align: 'center',
        },
        {
          title: 'Device type',
          dataIndex: 'studyGroup',
          ellipsis: true,
          align: 'center',
        },
        {
          title: 'createtime',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center',
        },
        {
          title: 'remark',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center',
        },
        {
          title: 'controls',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center',
        },
      ],
    }
  },
}
</script>

<style scoped lang="less">
.content {
  .btn {
    width: 108px;
    height: 38px;
    align-items: center;
    background-color: #7f62ad;
    border-color: #7f62ad;
    box-shadow: none !important;
    display: flex;
    // white-space: pre;
    color: #fff;
    border-radius: 0.25rem;
    font-weight: 500;
    font-size: 16px;
  }
}
</style>