<template>
  <div class="content">
    <div>
      <a-card style="width: 100%;border-radius:4px">
        <div class="top-title">
          <a-icon type="smile" style="font-size: 38px; color: rgb(127, 98, 173); margin-right: 20px" />
          Sensor Data
        </div>
        <div class="top-middle">Display all recorded CGM raw values and the glucose concentration (SMBG), which were measured with the associated glucometer.</div>
         <div style="margin-top:16px">
               <a-input v-model="value" placeholder="enter min. 4-digit serial number" />
        </div>
        <div>
          <a-button  class="btn" @click="openModal"
            >Fetch results
            <!-- <a-icon type="plus" style="font-size:20px; color: rgb(127, 98, 173); margin-right: 20px" -->
          <!-- /> -->
          </a-button>
        </div>
      </a-card>
    </div>
    <div style="margin-top:16px">
      <a-card style="width: 100%;border-radius:4px">
            <div>
                   <Table />
            </div>
      </a-card>  
    </div>

    <!-- 弹出框 -->
    <!-- <Modal re="ModalRefs" /> -->
  </div>
</template>

<script>
import Table from './modules/table.vue'
// import Modal from './modules/Modal.vue'
export default {
       data(){
          return {
                
          }
       } ,
       components:{
            Table,
            // Modal
       },
       methods:{
              openModal(){
                   this.$refs.ModalRefs.openModal()
              }
       }
}
</script>

<style scoped lang="less">
.content {
  width: 100%;
  height: 100%;

  .top-title {
    align-items: center;
    display: flex;
    font-size: 1.6rem;
    font-weight: 600;
    color: #212529;
  }

  .top-middle {
    margin-top: 8px;
    font-size: 16px;
    color: #212529;
    font-family: Poppins, sans-serif;
  }

  .btn{
       color: rgb(127, 98, 173);
       background-color: rgb(127, 98, 173);
       font-weight: 500;
       font-size: 16px;
       margin-top: 16px;
       border-radius: .25rem;
       width: 126px;
       height: 38px;
       color: #fff;
  }
}
</style>